@import '../../styles/variables.scss';
@import '../../styles/utils.scss';

.notfound {
  @include resetelement();
  .inner {
    @include resetelement();
    padding: $inner-content-padding;
  }
}

@media(max-width: $mobile-width) {
}
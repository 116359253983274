@import "../../styles/variables.scss";
@import "../../styles/utils.scss";

.rsvp {
  @include resetelement();
  .rsvp-message-wrapper {
    @include resetelement();
    margin: 100px auto;
    width: 50%;
    background-color: #ae2012;
    padding-top: 100px;
    padding-bottom: 60px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    .rsvp-title {
      @include resetelement();
      font-family: "Chelsea Market", cursive;
      color: white;
      text-align: center;
      font-size: 30px;
      padding: 10px;
    }
    .rsvp-message-message {
      @include resetelement();
      text-align: center;
      font-family: "Chelsea Market", cursive;
      color: #ee9b00;
      padding: 10px;
    }
    .rsvp-button {
      display: table;
      margin: 0 auto;
      margin-top: 40px;
      text-align: center;
      text-decoration: none;
      background-color: #ee9b00;
      padding: 20px 30px;
      color: white;
      font-family: "Chelsea Market", cursive;
      border-radius: 10px;
    }
  }
  .rsvp-box {
    @include resetelement();
    margin: 100px auto;
    width: 60%;
    .rsvp-wrapper {
      @include resetelement();
      padding: 10px 30px;
      background-color: #ae2012;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      .rsvp-bg {
        @include resetelement();
        position: absolute;
        right: 80px;
        top: 80px;
        height: 200px;
      }
      h2 {
        color: white;
      }
      p {
        color: white;
        line-height: 1.6;
      }
      a {
        color: white;
        text-decoration: underline;
      }
      .meta {
        width: 50%;
      }
      select,
      input,
      textarea {
        @include resetelement();
        // display: table;
        // margin: 0 auto;
        width: 100%;
        // height: 30px;
        background-color: #9b2226;
        border: none;
        outline: 0;
        border-bottom: 3px solid #9b2226;
        padding-bottom: 0px;
        color: #ee9b00;
        font-size: 20px;
        border-radius: 3px;
        overflow: hidden;
        // text-align: center;
        padding: 8px 15px;
        padding-top: 10px;
        height: 43.5px;
      }
      input::placeholder {
        color: #c4c4c4;
      }
      input:hover {
        // do not resetelement
        border-bottom: 3px solid #ca6702;
      }
      .columns {
        .column {
          padding-left: 5px;
          padding-right: 5px;
        }
      }
      .rsvp-button {
        @include resetelement();
        margin: 50px auto;
        display: table;
        padding: 10px 30px;
        margin-top: 20px;
        color: white;
        background-color: #ca6702;
        border: none;
        border-radius: 10px;
        font-weight: bold;
        font-size: 20px;
        font-family: "Chelsea Market", cursive;
        // box-shadow: rgba(202, 103, 2, 0.5) 0px 7px 29px 0px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      }
      .rsvp-button:hover {
        cursor: pointer;
      }
    }
  }
}

@media (max-width: $mobile-width) {
  .rsvp {
    .rsvp-message-wrapper {
      padding-top: 50px;
      width: 80%;
    }
    .rsvp-box {
      margin: 0;
      width: 100%;
      .rsvp-wrapper {
        border-radius: 0;
        
        .rsvp-bg {
          position: relative;
          margin: 50px auto;
          right: inherit;
          top: inherit;
          height: 150px;
        }
        .meta {
          width: 100%;
        }
        .columns {
          .column {
            width: 100%;
            select,
            input,
            textarea {
              margin: 5px 0;
            }
          }
        }
      }
    }
  }
}

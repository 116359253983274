@import "./variables.scss";
@import "./utils.scss";

.form {
  margin: 0 auto;
  padding: 25px 20px;
  display: block;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.form-control {
  @include resetelement();
  padding: 8px 15px;
  display: block;
  clear: both;
}

.form-control .info {
  @include resetelement();
  float: right;
}

.form-control label {
  @include resetelement();
  padding: 5px 0;
  padding-bottom: 15px;
  display: block;
  box-sizing: border-box;
  font-size: 17px;
  text-transform: uppercase;
  color: #666;
  width: 100%;
}

.form-control textarea {
  @include resetelement();
  padding: 15px 15px;
  display: block;
  box-sizing: border-box;
  font-size: 18px;
  width: 100%;
  overflow: hidden;
  min-height: 300px;
  border: 1px solid whitesmoke;
  border-radius: 4px;
}

.form-control input {
  @include resetelement();
  padding: 15px 15px;
  display: block;
  box-sizing: border-box;
  font-size: 19px;
  width: 100%;
  overflow: hidden;
  border: 1px solid whitesmoke;
  border-radius: 4px;
}

.form-control input:hover {
  border-color: gray;
}

.form-control a {
  @include resetelement();
  margin-top: 10px;
  padding: 0;
  display: block;
  float: left;
  font-size: 14px;
  text-decoration: none;
}

.form-control a:hover {
  text-decoration: underline;
}

.form-control button {
  @include resetelement();
  padding: 5px 10px;
  display: block;
  float: right;
  background-color: var(--DARK_PINK);
  border: 1px solid var(--DARK_PINK);
  font-size: 18px;
  color: white;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
}

.form-control button:hover {
  background-color: white;
  color: #53ae82;
}

@import "../../styles/variables.scss";
@import "../../styles/utils.scss";

.home {
  @include resetelement();
  .hero {
    @include resetelement();
    margin-bottom: 80px;
    // height: 1024px;
    // background-size: cover;
    // max-height: 1100px;
    overflow: hidden;
    background-color: black;
    position: relative;
    .container {
      position: relative;
    }
    img.hero-preview {
      @include resetelement();
      margin: 0 auto;
      width: 100%;
      max-width: 1024px;
      opacity: 0.6;
    }
    .gradient-bg {
      @include resetelement();
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(42, 69, 120, 0) 10%,
        rgba(22, 36, 62, 0) 90%,
        rgba(0, 0, 0, 1) 100%
      );
    }
    .heart-wrapper {
      @include resetelement();
      position: absolute;
      left: 50px;
      bottom: 50px;
      img {
        // height: 500px;
        width: 40%;
      }
    }
    .hero-heading {
      @include resetelement();
      background-color: #9b2226;
      padding: 10px;
      position: absolute;
      left: 50px;
      bottom: 50px;
    }
  }
  .home-section {
    margin-top: 70px;
  }
  .content-heading {
    @include resetelement();
    color: #9b2226;
    font-size: 40px;
    font-family: "Chelsea Market", cursive;
    text-align: center;
  }
  .content-heading.blue-theme {
    color: #005f73;
  }
  .content-sub-heading {
    @include resetelement();
    color: #ca6702;
    text-align: center;
    padding-top: 20px;
  }
  .content-sub-heading.blue-theme {
    color: #0a9396;
    font-size: 20px;
  }
  .content-sub-heading.blue-theme.details {
    color: gray;
    font-size: 20px;
  }
  .section-subheading {
    @include resetelement();
    color: #ca6702;
    text-align: center;
    padding-top: 20px;
    margin: 0 auto;
    max-width: 70%;
  }
  .section-subheading.blue-theme {
    color: #0a9396;
  }
  .box-heading {
    color: #e9d8a6;
  }

  .rsvp-heading {
    color: white;
    margin-top: 100px;
    text-align: center;
  }
  .helsinki-heading {
    color: white;
    margin-top: 0px;
    text-align: center;
  }
  .rsvp-form {
    @include resetelement();
    .rsvp-input-box {
      @include resetelement();
      display: table;
      margin: 0 auto;
      width: 70%;
      height: 30px;
      background-color: #9b2226;
      border: none;
      outline: 0;
      border-bottom: 0.4px solid #ca6702;
      padding-bottom: 0px;
      color: #ee9b00;
      font-size: 20px;
      text-align: center;
    }
    .rsvp-input-box::placeholder {
      color: #c4c4c4;
    }
    .rsvp-input-box:hover {
      // do not resetelement
      border-bottom: 3px solid #ca6702;
    }
    .rsvp-button {
      @include resetelement();
      margin: 0 auto;
      display: table;
      padding: 10px 30px;
      margin-top: 20px;
      color: white;
      background-color: #ca6702;
      border: none;
      border-radius: 10px;
      font-weight: bold;
      font-size: 20px;
      font-family: "Chelsea Market", cursive;
      // box-shadow: rgba(202, 103, 2, 0.5) 0px 7px 29px 0px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }
    .rsvp-button:hover {
      cursor: pointer;
    }
  }
  .split-box {
    @include resetelement();
    margin: 0 auto;
    width: 900px;
    min-height: 500px;
    box-sizing: border-box;
    background-color: #9b2226;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 30px;
    .left-box {
      @include resetelement();
      width: 50%;
      padding: 20px;
      height: 100%;
      position: relative;
      float: left;
    }
    .right-box {
      @include resetelement();
      width: 50%;
      min-height: 500px;
      height: 100%;
      position: relative;
      float: left;
      overflow: hidden;
      .right-box-img {
        position: absolute;
        top: 0px;
        left: -50px;
        bottom: 0px;
        right: 0px;
        background-size: cover;
      }
    }
  }
  .split-box.blue-theme {
    background-color: #94d2bd;
    // height: 400px;
    min-height: 400px;
    width: 100%;

    .left-box {
      @include resetelement();
      width: 60%;
      height: 100%;
      position: relative;
      float: left;
      overflow: hidden;
      .left-box-img {
        position: relative;
        float: left;
        background-size: cover;
      }
    }
    .right-box {
      @include resetelement();
      width: 40%;
      padding: 20px;
      height: 100%;
      min-height: 400px;
      position: relative;
      float: left;
      .right-box-img {
        position: relative;
        float: right;
        background-size: cover;
      }
    }
    .right-box-mehendi {
    }
    .right-box-ceremony {
      padding: 100px 50px;
    }
    .right-box-party {
      padding: 110px 50px;
    }
    .split-box-c50 {
      width: 50%;
    }
  }

  .split-box.red-theme {
    min-height: 800px;
    width: 100%;

    .left-box {
      @include resetelement();
      width: 60%;
      height: 100%;
      position: relative;
      float: left;
      overflow: hidden;
      .left-box-img {
        position: relative;
        float: left;
        background-size: cover;
      }
    }
    .right-box {
      @include resetelement();
      width: 40%;
      padding: 20px;
      height: 100%;
      position: relative;
      float: left;
    }
    .right-box-thingstodo {
      padding: 100px 50px;
    }
  }

  .venue-gallery-items {
    @include resetelement();
    margin: 10px 0;
    .venue-gallery-item {
      @include resetelement();
      padding: 5px;
      float: left;
      width: calc(100% / 4);
      height: 150px;
      overflow: hidden;
      border-radius: 6px;
      img {
        @include resetelement();
        width: 100%;
      }
    }
  }
  .mapbox {
    @include resetelement();
    padding: 20px 0;
  }
  .mapview {
    @include resetelement();
    width: 50%;
    height: 300px;
    position: left;
    float: left;
    padding: 0;
    border-radius: 10px;
    border: 1px solid transparent;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    .mapview-venue-marker {
      @include resetelement();
      cursor: "pointer";
      color: #ee9b00;
      font-size: 40px;
      border-radius: 80px;
      // width: 40px;
      // height: 40px;
      // padding-top: 3px;
      margin-top: -30px;
    }
  }
  .location-box {
    @include resetelement();
    padding: 35px 35px;
    position: relative;
    float: left;
    width: 50%;
    height: auto;
    text-align: left;
    .title {
      color: #ae2012;
      font-size: 25px;
      margin: 0;
    }
    .meta-data {
      color: #ee9b00;
      font-size: 15px;
    }
  }
  .fa-location-pin {
    font-size: 100px;
    height: 30px;
    width: 30px;
    background-color: #ca6702;
  }
  .footer {
    @include resetelement();
    margin-top: 100px;
    width: 100%;
    background-color: #9b2226;
    padding: 25px 0;
    .footer-text {
      @include resetelement();
      color: white;
      text-align: center;
    }
  }
}

@media (max-width: $mobile-width) {
  .home {
    .hero {
      margin-bottom: 30px;
      .heart-wrapper {
        // top: 30px;
        left: 20px;
        bottom: 20px;
        img {
          width: 50%;
        }
      }
    }
    .content-heading {
      font-size: 32px;
    }
    .section-subheading {
      margin-bottom: 50px;
      max-width: 90%;
    }
    .split-box.blue-theme {
      .left-box {
        min-height: auto;
      }
      .right-box {
        min-height: auto;
      }
    }
    .mapbox {
      margin: 0 auto;
      width: 95%;
      .mapview {
        width: 100%;
      }
      .location-box {
        width: 100%;
      }
    }
    .venue-gallery-items {
      margin: 0 auto;
      width: 85%;
      .venue-gallery-item {
        width: 50%;
      }
    }
    .rsvp-form {
      margin-bottom: 80px;
    }
    .container {
      .split-box {
        width: 95%;
        margin: 0 auto;
        margin-top: 50px;
        .left-box {
          width: 100%;
        }
        .right-box {
          width: 100%;
        }
      }
      .split-box.red-theme {
        .right-box-thingstodo {
          padding: 50px 50px;
        }
      }
      .split-box.blue-theme {
        margin: 50px auto;
        .right-box-mehendi {
          padding: 50px 50px;
        }
        .right-box-ceremony {
          padding: 50px 50px;
        }
        .right-box-party {
          padding: 50px 50px;
        }
      }
    }
    .home-section {
      .split-box {
        .left-box {
          width: 100%;
        }
        .right-box {
          width: 100%;
        }
      }
    }
  }
}

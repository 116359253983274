@import "variables.scss";
@import "utils.scss";

.appwrapper {
  @include resetelement();
  height: 100%;
}

.loading-page-container {
  @include resetelement();
  padding: 20% 0;
}

.contentwrapper {
  @include resetelement();
  // height: 93%;
  position: absolute;
  top: $header-height;
  bottom: 0;
  left: 0;
  right: 0;
}

.contentsidebar,
.contentpage {
  @include resetelement();
  height: 100%;
  overflow: auto;
  float: left;
  transition: width 0.35s;
}

.contentsidebar {
  background-color: var(--BG_COLOR_ACCENT);
  width: $sidebar-width;
}

.contentsidebar.collapsed {
  width: 5%;
}

.contentsidebar.collapsed + .contentpage {
  width: 95%;
}

.contentpage {
  background-color: var(--BG_COLOR);
  width: 100% - $sidebar-width;
}

.container {
  @include resetelement();
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  max-width: 1024px;
  font-family: "Chelsea Market", cursive;
}

.columns {
  @include resetelement();
  .column {
    @include resetelement();
    float: left;
  }
  .column-medium {
    width: 50%;
  }
  .column-small {
    width: 25%;
  }
  .column-large {
    width: 75%;
  }
  .column-xlarge {
    width: 100%;
  }
}

.banner {
  margin: 0;
  padding: 10px 30px;
  display: block;
  box-sizing: border-box;
  clear: both;
  border: 1px solid #ffeeba;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  overflow: hidden;

  color: #856404;
  background-color: #faed85;
  text-transform: uppercase;
  position: relative;
  line-height: 1.7;
}

.banner-progress {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.banner .progress {
  margin: 0;
  margin-top: -1px;
  padding: 0;
  display: block;
  box-sizing: border-box;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  width: 0%;
  background-color: red;
}

.banner .text {
  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  clear: both;
  max-width: 65%;
  text-align: center;

  color: #856404;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}

.banner-hero {
  margin-bottom: -10px;
}

.progress-overlay {
  @include resetelement();
  clear: both;
  background-color: --BG_COLOR;
  opacity: 0.92;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 40000;
}

.progress-wrapper {
  margin: 0;
  padding: 0;
  padding-top: 350px;
  display: block;
  box-sizing: border-box;
  clear: both;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50000;
}

.progress-text {
  margin: 0 auto;
  padding: 0;
  display: table;
  box-sizing: border-box;
  color: white;
  text-align: center;
}

.progress-box-container {
  margin: 0 auto;
  padding: 0;
  display: table;
  position: relative;
  box-sizing: 0;
}

.progress-img {
  margin: 25px auto;
  padding: 0;
  display: table;
  position: relative;
  height: 128px;
}

.progress-box {
  margin: 0 auto;
  padding: 0;
  display: block;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
}

.progress-box:before {
  content: "";
  width: 50px;
  height: 5px;
  background: white;
  opacity: 0.1;
  position: absolute;
  top: 59px;
  left: 0;
  border-radius: 50%;
  animation: shadow 0.5s linear infinite;
}

.progress-box:after {
  content: "";
  width: 50px;
  height: 50px;
  background: white;
  animation: animate 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}

.app-layout-sidebar {
  margin: 0;
  padding: 0;
  display: block;
  float: left;
  width: 25%;
}

.section-heading {
  margin: 0 auto;
  font-size: 20;
  color: white;
}

@keyframes animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow {
  0%,
  100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1);
  }
}

@media (max-width: $mobile-width) {
  .wrapper {
    margin-top: 130px;
  }

  .banner .text {
    max-width: 90%;
  }

  .contentsidebar {
    width: 0;
    display: none;
  }

  .contentpage {
    width: 100%;
    overflow: visible;
  }

  body {
    overflow: inherit;
  }
}
